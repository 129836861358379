import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '32, 33, 79',
		'primary-dark': '10, 11, 51',
		'accent': '233, 82, 34',
		'accent-plus': '255, 255, 255',
	},
});
